
import { Mixins, Component } from "vue-property-decorator";
// @ts-ignore-start
import colores from "@/scss/colores.scss";
import LabelHelpers from "@/mixins/LabelHelpers";
// @ts-ignore-end

@Component({
  components: {
    colores
  }
})
export default class MyRequestList extends Mixins(
  LabelHelpers
) {
  private tickets = [];
  private option_status: Array<any> = [];

  finish_loading: any = null;
  loading_status: Boolean = false;

  private hello: Boolean = false;

  private filter_status: any = null;

  private created() {
    this.loading_status = true;

    this.$q.loading.show({
      delay: 100 // ms
    });
    
    this.getData();
    this.getStatusOptions();

  }

  private getData(){
    this.$axios
      .get("tickets/listar/" + this.$store.state.user.user_id, {
        params: {
          ...(this.filter_status && { estado: this.filter_status })
        },
        headers: {
          Authorization: "Bearer " + this.$store.state.user.auth_token
        }
      })
      .then(response => {
        this.tickets = response.data as any;

        this.$emit("visible", {
          visible: this.finish_loading
        });
      })
      .catch(error => {
        this.$q.notify({
          message: "Hubo un problema cargando los datos. Intenta de nuevo en unos minutos",
          color: "principal",
          position: "top",
          timeout: 5000
        });
      })
      .finally(() => {
        this.$q.loading.hide();
        this.finish_loading = true;
      });
  }

  private getStatusOptions(){
    this.$axios.get("ticket-estados", {
      params: {
        filter: {
          where: {
            and: [
              { estado: 1 },
              { publico: 1 }
            ]
          },
          fields: ['id', 'nombre']
        }
      },
      headers: {
        Authorization: "Bearer " + this.$store.state.user.auth_token
      }
    }).then(response => {
      this.option_status = response.data
    }).catch(error => {
      this.$q.notify({
        message: "Hubo un problema cargando los filtros. Intenta de nuevo en unos minutos",
        color: "principal",
        position: "top",
        timeout: 5000
      });
    }).finally(() => {
      this.loading_status = false;
    })
  }

  /*private getTextColor(status: number) {
    let color = "";
    switch (status) {
      case 1:
        color = colores.color_estado_uno;
        break;
      case 2:
        color = colores.color_estado_dos;
        break;
      case 3:
        color = colores.color_estado_tres;
        break;
      case 4:
        color = colores.color_estado_diez;
        break;
      case 5:
        color = colores.color_estado_cinco;
        break;
      case 6:
        color = colores.color_estado_seis;
        break;
      case 9:
        color = colores.color_estado_siete;
        break;
      case 10:
        color = colores.color_estado_tres;
        break;
      default:
        color = "";
        break;
    }
    return color;
  }

  private getBtnColor(status: number) {
    let color = "";
    switch (status) {
      case 1:
        color = colores.color_estado_uno;
        break;
      case 2:
        color = colores.color_estado_dos;
        break;
      case 3:
        color = colores.color_estado_tres;
        break;
      case 4:
        color = colores.color_estado_diez;
        break;
      case 5:
        color = colores.color_estado_cinco;
        break;
      case 6:
        color = colores.color_estado_seis;
        break;
      case 9:
        color = colores.color_estado_siete;
        break;
      case 10:
        color = colores.color_estado_tres;
        break;
      case 15:
        color = colores.color_primario_dos;
        break;
      default:
        color = "";
        break;
    }
    return color;
  }

  private getStateColor(status: number) {
    let color = "";
    switch (status) {
      case 1:
        color = colores.color_estado_uno;
        break;
      case 2:
        color = colores.color_estado_dos;
        break;
      case 3:
        color = colores.color_estado_tres;
        break;
      case 4:
        color = colores.color_estado_diez;
        break;
      case 5:
        color = colores.color_estado_cinco;
        break;
      case 6:
        color = colores.color_estado_seis;
        break;
      case 9:
        color = colores.color_estado_siete;
        break;
      case 10:
        color = colores.color_estado_tres;
        break;
      case 13:
        color = colores.color_estado_diez;
        break;
      case 15:
        color = colores.color_primario_dos;
        break;
      case 16:
        color = colores.color_estado_catorce;
        break;
      default:
        color = "";
        break;
    }
    return color;
  }*/

  private humanDate(date: any) {
    return (
      date.split("")[8] +
      date.split("")[9] +
      "-" +
      date.split("")[5] +
      date.split("")[6] +
      "-" +
      date.split("")[0] +
      date.split("")[1] +
      date.split("")[2] +
      date.split("")[3]
    );
  }
}
