var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row col-12",staticStyle:{"margin-bottom":"70px"}},[(_vm.finish_loading)?_c('q-card',{staticStyle:{"border-radius":"10px","width":"100%"},attrs:{"flat":""}},[_c('q-card-section',[_c('div',{staticClass:"row col-12 q-mt-sm"},[_c('div',{staticClass:"col q-pa-sm"},[_c('q-select',{staticClass:"q-pb-none",attrs:{"outlined":"","emit-value":"","map-options":"","clearable":"","color":"principal","options":_vm.option_status,"dense":true,"options-dense":true,"option-label":"nombre","option-value":"id","label":"Estado","bg-color":'white'},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('q-icon',{attrs:{"color":"principal","name":"credit_card"}})]},proxy:true}],null,false,1941079827),model:{value:(_vm.filter_status),callback:function ($$v) {_vm.filter_status=$$v},expression:"filter_status"}})],1),_c('div',{staticClass:"col-xs-12 col-sm-2 col-md-1 col-lg-1 col-xl-1 text-right q-pa-sm"},[_c('q-btn',{staticClass:"btn-flat full-width",staticStyle:{"max-height":"40px"},attrs:{"disabled":_vm.loading_status,"unelevated":"","icon":"search","color":"principal"},on:{"click":function($event){return _vm.getData()}}},[_c('q-tooltip',[_vm._v(" Aplicar filtros ")])],1)],1)])])],1):_vm._e(),(_vm.tickets.length == 0)?_c('div',{staticClass:"row col-12"},[_c('q-card',{staticClass:"bg-aviso"},[_c('q-card-section',[_c('div',{staticClass:"row col-12"},[_c('div',{staticClass:"col-12 text-center"},[_vm._v(" No tiene ticket solicitados ")])])])],1)],1):_c('div',{staticClass:"col-12"},[_c('q-scroll-area',{staticStyle:{"height":"480px"}},_vm._l((_vm.tickets),function(item){return _c('q-card',{key:item.id,staticClass:"q-my-lg cursor-pointer",staticStyle:{"border-radius":"15px"},on:{"click":function($event){return _vm.$router.push({
            name: 'requestdetailsnew',
            params: { id_ticket: item.correlativo }
          })}}},[_c('q-toolbar',{staticClass:"bg-card-tickets"},[_c('div',{staticClass:"row justify-between full-width items-center"},[_c('div',{staticClass:"q-py-md items-center row q-pl-sm"},[_c('label',{staticStyle:{"font-family":"'Font-principal Medium'"}},[_vm._v("N° Ticket: ")]),_c('label',{staticClass:"text-ticket q-ml-sm"},[_vm._v(_vm._s(item.correlativo ? item.correlativo : item.id)+" ")])]),([1,4,5,8,13,16,17,18].includes(item.estado_ticket))?_c('q-card',{staticClass:"btn-ticket-list"},[_c('q-card-section',{staticClass:"q-pa-sm padding-mobile",style:({
                  'background-color': _vm.getColor(item.estado_ticket)
                })},[_c('label',{staticClass:"row justify-center",staticStyle:{"font-family":"'Font-principal Medium'","color":"#202020"}},[_vm._v(" "+_vm._s(item.nombre_estado)+" ")])]),(item.estado_ticket == 1)?_c('q-tooltip',{attrs:{"offset":[10, 10]}},[_c('label',{staticStyle:{"font-size":"14px !important"}},[_vm._v(" "+_vm._s(_vm.$t('labels.my_request_list.state_one_helper'))+" ")])]):_vm._e()],1):_c('q-card',{staticClass:"btn-ticket-list"},[_c('q-card-section',{staticClass:"q-pa-sm padding-mobile",style:({
                  'background-color': _vm.getColor(item.estado_ticket)
                })},[_c('label',{staticClass:"row justify-center text-white",staticStyle:{"font-family":"'Font-principal Medium'"}},[_vm._v(" "+_vm._s(item.nombre_estado)+" ")])]),(item.estado_ticket == 2)?_c('q-tooltip',{attrs:{"offset":[10, 10]}},[_c('label',{staticStyle:{"font-size":"14px !important"}},[_vm._v(" "+_vm._s(_vm.$t('labels.my_request_list.state_two_helper'))+" ")])]):_vm._e(),(item.estado_ticket == 13)?_c('q-tooltip',{attrs:{"offset":[10, 10]}},[_c('label',{staticStyle:{"font-size":"14px !important"}},[_vm._v(" "+_vm._s(_vm.$t('labels.my_request_list.waiting_state_helper'))+" ")])]):_vm._e(),(item.estado_ticket == 6)?_c('q-tooltip',{attrs:{"offset":[10, 10]}},[_c('label',{staticStyle:{"font-size":"14px !important"}},[_vm._v(" "+_vm._s(_vm.$t('labels.my_request_list.closed_state_helper'))+" ")])]):_vm._e()],1)],1)]),_c('q-card-section',[_c('div',{staticClass:"row justify-between col-12"},[_c('div',{staticClass:"row col-11"},[_c('div',{staticClass:"col-12 text-card-tickets"},[_vm._v(" "+_vm._s(item.servicio_nombre)+" ")]),_c('div',{staticClass:"col-12 text-bold",staticStyle:{"font-family":"'Font-principal'"}},[_vm._v(" "+_vm._s(_vm.humanDate(item.fecha))+" ")]),_c('div',{staticClass:"col-12",staticStyle:{"font-family":"'Font-principal'","margin-top":"10px"},domProps:{"innerHTML":_vm._s(item.mensaje)}})]),_c('div',{staticClass:"row justify-end items-center col-1"},[_c('q-icon',{staticClass:"cursor-pointer",staticStyle:{"font-size":"35px"},attrs:{"name":"chevron_right","color":"principal"},on:{"click":function($event){return _vm.$router.push({
                    name: 'requestdetailsnew',
                    params: { id_ticket: item.correlativo }
                  })}}})],1)])])],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }